body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgb(237, 246, 248);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bedroom {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://bluprint.onemega.com/wp-content/uploads/2019/03/luxe-bedroom-featured.jpg");
}

.text-white {
  color: white;
}
.box-heading-container {
  padding: 10%;
}
.living-room {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://www.magnonindia.com/wp-content/uploads/2019/12/blog-post_4_6-1080x600.jpg");
}
.office {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://mega.onemega.com/wp-content/uploads/2020/03/Interior-Design-featured.jpg");
}

.dining {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://i.pinimg.com/originals/ed/62/dc/ed62dc0165a65ea868b9ad26032991ad.jpg");
}

.wardrobe {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://i.pinimg.com/originals/f1/82/90/f182907222a585c49fc4cf59358a6bf6.jpg");
}
.chair {
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
    url("https://imgix.bustle.com/uploads/image/2020/1/13/59c0ab83-8284-4c64-b12a-1e28e515b0b3-shutterstock_1162397740.jpg?w=1080&h=600&fit=crop&crop=faces&auto=format%2Ccompress&cs=srgb&q=70");
}
.bill {
  display: block;
  text-transform: uppercase;
}
.bill > li {
  margin: 10px;
  list-style-type: none;
}

.printme {
  display: block;
}
@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
}
.res-img {
  width: 100%;
  padding: 10px;
}
.navbar-nav {
  margin-left: auto;
}
.help {
  list-style-type: none;
}
